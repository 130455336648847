<template>
  <div>
    <MainView/>
  </div>
</template>

<script>
import MainView from './components/MainView.vue';
export default {
  components: {
    MainView
  }
};
</script>